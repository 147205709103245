import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js'
import i18n from 'i18next'
import React from 'react'
import { Button, Card, Form, Grid } from 'semantic-ui-react'
import TokenAuthService from 'service/auth/TokenAuthService'
import StripeService from 'service/stripe/StripeService'

interface Props {
  stripe: any
  elements: any
}

interface State {
  token: string
}

class CardSetupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      token: '',
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentDidMount() {
    const params = new URLSearchParams(document.location.search)
    const login_token = params.get('token') || ''
    if (login_token !== '') {
      document.location.href = '/login?token=' + login_token
    } else {
      const continueToken = sessionStorage.getItem('continue_token')
      if (continueToken === null) {
        document.location.href = '/'
      } else {
        try {
          await TokenAuthService.CheckContinueToken(continueToken)
          await this.setState({
            token: continueToken,
          })
        } catch {
          document.location.href = '/'
        }
      }
    }
  }

  handleInputChange(event: { target: { name: any; value: any } }) {
    const newState = { [event.target.name]: event.target.value } as Pick<
      State,
      keyof State
    >
    this.setState(newState)
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { stripe, elements } = this.props
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    const paymentIntent = await StripeService.confirmCardSetup(
      stripe,
      elements,
      this.state.token,
    )
    await StripeService.setMainPaymentMethod(
      this.state.token,
      paymentIntent.setupIntent.payment_method,
    )
    document.location.href = '/cards'
  }

  render() {
    const mystyle = {
      style: {
        base: {
          color: '#32325d',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    }
    return (
      <Grid centered>
        <Grid.Row columns={2} centered>
          <Grid.Column>
            <Card
              style={{
                width: '100%',
                maxWidth: '900px',
                padding: '10px',
                background: '#f7fafc',
              }}
            >
              <Card.Content>
                <Card.Header>{i18n.t('card_setup.title')}</Card.Header>
              </Card.Content>
              <Card.Content style={{}}>
                <Form style={{ paddingTop: '20px' }}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>{i18n.t('card_setup.card_number')}</label>
                      <CardNumberElement options={mystyle} />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>{i18n.t('card_setup.card_month_year')}</label>
                      <CardExpiryElement options={mystyle} />
                    </Form.Field>
                    <Form.Field>
                      <label>{i18n.t('card_setup.card_cvc')}</label>
                      <CardCvcElement options={mystyle} />
                      <p style={{ paddingTop: '5px' }}>
                        {i18n.t('card_setup.cvc_help')}
                      </p>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal" style={{ float: 'right' }}>
                    <Button
                      className="regist-card"
                      secondary
                      disabled={!this.props.stripe}
                      onClick={this.handleSubmit}
                    >
                      {i18n.t('common.save')}
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const CardSetup = () => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CardSetupForm elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  )
}

export default CardSetup
