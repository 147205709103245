import React from 'react'
import {
  Loader,
  Grid,
  Card,
  Form,
  Input,
  Button,
  Table,
  Icon,
  DropdownProps,
  DropdownItemProps,
} from 'semantic-ui-react'
import Toast from '../common/Toast'
import i18n from 'i18next'
import invoiceSettingsService, {
  Prefecture,
} from 'service/invoice/invoiceSettingsService'
import TokenAuthService from 'service/auth/TokenAuthService'

interface Props {}

interface State {
  isLoading: boolean
  token: string
  miitel_id: string
  official_account_name: string
  postal_code: string
  city?: Prefecture
  state: string
  street: string
  invoice_phone: string
  invoice_addressee: string
  invoice_to: string
  invoice_cc: string[]
}

class InvoiceSettingsFrom extends React.Component<Props, State> {
  EMAILOKPATTERN = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
  NO_SELECTION = {
    text: '選択してください',
    value: '',
  }
  PREFECTURE: DropdownItemProps[] = [this.NO_SELECTION]

  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: true,
      token: '',
      postal_code: '',
      state: '',
      street: '',
      invoice_phone: '',
      invoice_addressee: '',
      invoice_to: '',
      invoice_cc: [],
      miitel_id: '',
      official_account_name: '',
    }
    this.validateForm = this.validateForm.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.loadInvoiceSettings = this.loadInvoiceSettings.bind(this)
    this.addEmail = this.addEmail.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
    this.changeEmail = this.changeEmail.bind(this)

    const C = Object.keys(Prefecture)
    C.forEach((a) => {
      this.PREFECTURE.push({
        text: a,
        value: a,
      })
    })
  }
  async componentDidMount() {
    const params = new URLSearchParams(document.location.search)
    const login_token = params.get('token') || ''
    if (login_token !== '') {
      document.location.href = '/login?token=' + login_token
    } else {
      const continueToken = sessionStorage.getItem('continue_token')
      if (continueToken === null) {
        document.location.href = '/'
      } else {
        try {
          await TokenAuthService.CheckContinueToken(continueToken)
          await this.setState({
            token: continueToken,
          })
        } catch {
          document.location.href = '/'
        }
      }
      await this.loadInvoiceSettings()
    }
  }

  handleInputChange(event: { target: { name: any; value: any } }) {
    const newState = { [event.target.name]: event.target.value } as Pick<
      State,
      keyof State
    >
    this.setState(newState)
  }

  handleOptionChange(_, data: DropdownProps) {
    const newState = { [data.name]: data.value } as Pick<State, keyof State>
    this.setState(newState)
  }

  validateForm() {
    let cnt = 0

    if (!this.state.postal_code) {
      Toast.error(
        i18n.t('common.please_input_suffix', {
          item: i18n.t('invoice_settings.postal_code'),
        }),
      )
      cnt++
    }
    if (!this.state.city) {
      Toast.error(
        i18n.t('common.please_input_suffix', {
          item: i18n.t('invoice_settings.city'),
        }),
      )
      cnt++
    }
    if (!this.state.state) {
      Toast.error(
        i18n.t('common.please_input_suffix', {
          item: i18n.t('invoice_settings.address1'),
        }),
      )
      cnt++
    }
    if (!this.EMAILOKPATTERN.test(this.state.invoice_to)) {
      Toast.error(
        i18n.t('common.please_input_suffix', {
          item: i18n.t('common.email_format_error'),
        }),
      )
      cnt++
    }
    if (this.state.invoice_cc !== []) {
      this.state.invoice_cc.map((value) => {
        if (!this.EMAILOKPATTERN.test(value)) {
          Toast.error(
            i18n.t('common.please_input_suffix', {
              item: i18n.t('common.email_format_error'),
            }),
          )
          cnt++
        }
        return null
      })
    }

    if (!cnt) {
      return true
    }
    return false
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    if (!this.validateForm()) {
      return
    }
    await this.setState({
      isLoading: true,
    })
    const department_data = {
      postal_code: this.state.postal_code,
      city: this.state.city,
      state: this.state.state,
      street: this.state.street,
      invoice_to: this.state.invoice_to,
      invoice_cc: this.state.invoice_cc,
    }
    await invoiceSettingsService.updateInvoiceSettings(
      this.state.token,
      department_data,
    )
    await this.loadInvoiceSettings()
  }

  loadInvoiceSettings = async () => {
    try {
      const res = await invoiceSettingsService.getInvoiceSettings(
        this.state.token,
      )
      const billing = res.data.res
      await this.setState({
        isLoading: false,
        city: billing.Prefecture__c,
        state: billing.Address1__c,
        street: billing.Address2__c,
        postal_code: billing.ZipCode__c,
        invoice_to: billing.Email__c,
        invoice_cc:
          billing.CcMailAddress__c === null
            ? []
            : billing.CcMailAddress__c.split(','),
        miitel_id: res.data.miitel_id,
        official_account_name: res.data.official_account_name,
      })
    } catch (error) {
      document.location.href = '/'
    }
  }

  changeEmail(index, { value }) {
    const invoice_cc = this.state.invoice_cc
    invoice_cc.splice(index, 1, value)
    this.setState({
      invoice_cc,
    })
  }

  removeEmail(index) {
    const invoice_cc = this.state.invoice_cc
    invoice_cc.splice(index, 1)
    this.setState({
      invoice_cc,
    })
  }

  addEmail() {
    const invoice_cc = this.state.invoice_cc
    invoice_cc.push('')
    this.setState({
      invoice_cc,
    })
  }

  render() {
    if (this.state.isLoading) {
      const loader = (
        <div style={{ margin: 80 + 'px' }} key="loader">
          <Loader active inline="centered" />
        </div>
      )
      return loader
    }
    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column>
            <h1>請求先情報変更</h1>
            <h2>
              {this.state.official_account_name} 様 {this.state.miitel_id}{' '}
              テナント
            </h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} centered>
          <Grid.Column>
            <Card
              className="invoice-setting"
              style={{ width: '100%', maxWidth: '1200px', padding: '20px' }}
            >
              <Card.Content>
                <Card.Header>{i18n.t('invoice_settings.title')}</Card.Header>
                <div style={{ padding: '10px 0px' }}></div>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>{i18n.t('invoice_settings.postal_code')}</label>
                      <Input
                        className="postal-code"
                        value={this.state.postal_code}
                        onChange={this.handleInputChange}
                        name="postal_code"
                      />
                    </Form.Field>
                    <Form.Dropdown
                      label={i18n.t('invoice_settings.city')}
                      options={this.PREFECTURE}
                      placeholder={'選択してください'}
                      selection
                      value={this.state.city}
                      name={'city'}
                      onChange={this.handleOptionChange}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>{i18n.t('invoice_settings.address1')}</label>
                      <Input
                        className="state"
                        value={this.state.state}
                        onChange={this.handleInputChange}
                        name="state"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>{i18n.t('invoice_settings.address2')}</label>
                      <Input
                        className="street"
                        value={this.state.street}
                        onChange={this.handleInputChange}
                        name="street"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>{i18n.t('invoice_settings.invoice_to')}</label>
                      <Input
                        className="invoice-to"
                        value={this.state.invoice_to}
                        onChange={this.handleInputChange}
                        name="invoice_to"
                        type="email"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Table basic celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan={2}>
                          {i18n.t('invoice_settings.invoice_cc')}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.invoice_cc.map((email, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell width={14} textAlign="center">
                              <Input
                                name={'invoice_cc-' + index.toString()}
                                type="email"
                                value={email}
                                fluid
                                onChange={(_, data) => {
                                  this.changeEmail(index, data)
                                }}
                                placeholder="cc@example.com"
                              ></Input>
                            </Table.Cell>
                            <Table.Cell width={2} textAlign="center">
                              {index >= 0 ? (
                                <Icon
                                  name="trash"
                                  color="red"
                                  onClick={() => {
                                    this.removeEmail(index)
                                  }}
                                ></Icon>
                              ) : null}
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                    {this.state.invoice_cc.length < 5 ? (
                      <Table.Footer fullWidth>
                        <Table.Row textAlign="center">
                          <Table.HeaderCell colSpan="2">
                            <Icon
                              className="add-email"
                              onClick={this.addEmail}
                              color="black"
                              name="plus"
                            ></Icon>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    ) : null}
                  </Table>
                  <Form.Group widths="equal" style={{ float: 'right' }}>
                    <Button
                      className="set-invoice"
                      secondary
                      onClick={this.handleSubmit}
                    >
                      {i18n.t('invoice_settings.update')}
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default InvoiceSettingsFrom
