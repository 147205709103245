import axios from 'axios';

import { ConsoleAPIBaseURL } from 'config/BaseConfig'


export default class AuthService {

  static baseURL = ConsoleAPIBaseURL
  static headers= {
    'Authorization': "JWT " + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    'accept': 'application/json'
  }

  static async signIn(username, password){
    try {
      const res = await axios.post(this.baseURL + "account/api/v1/token/obtain/", 
        {
          username: username,
          password: password
        }, 
        { headers: this.headers })
      localStorage.setItem('access_token', res.data.access);
      localStorage.setItem('refresh_token', res.data.refresh);
      return res
    } catch (e){
      console.log("ログインに失敗しました。")
      throw e
    }
  }

  static async signUp(username, email, password){
    try {
      const res = await axios.post(this.baseURL + "account/api/v1/user/create/", 
        {
          username: username,
          email: email,
          password: password
        })
      return res
    } catch (e){
      console.log("サインアップに失敗しました。")
      throw e
    }
  }

  static async refreshToken(access_token, refresh_token){
    try {
      const res = await axios.post(this.baseURL + "account/api/v1/token/refresh/", 
        {
          refresh: refresh_token,
        })
      localStorage.setItem('access_token', res.data.access);
      localStorage.setItem('refresh_token', res.data.refresh);
      return res
    } catch (e) {
      console.log(e)
      document.location.href = '/login'
      throw e
    }
  }


}