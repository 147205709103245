import i18n from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Loader,
  Menu,
  Popup,
  Table,
} from 'semantic-ui-react'
import TokenAuthService from 'service/auth/TokenAuthService'
import StripeService from 'service/stripe/StripeService'

interface Card {
  id: string
  brand: string
  exp_month: string
  exp_year: string
  last4: string
  main: boolean
}

interface Props {
  stripe: any
  elements: any
}

interface State {
  isLoading: boolean
  token: string
  miitel_id: string
  official_account_name: string
  cards: Card[]
}

class CardList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: true,
      token: '',
      miitel_id: '',
      official_account_name: '',
      cards: [],
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.detachPaymentMethod = this.detachPaymentMethod.bind(this)
    this.setMainPaymentMethod = this.setMainPaymentMethod.bind(this)
    this.loadCards = this.loadCards.bind(this)
  }

  async componentDidMount() {
    const params = new URLSearchParams(document.location.search)
    const login_token = params.get('token') || ''
    if (login_token !== '') {
      document.location.href = '/login?token=' + login_token
    } else {
      const continueToken = sessionStorage.getItem('continue_token')
      if (continueToken === null) {
        document.location.href = '/'
      } else {
        try {
          await TokenAuthService.CheckContinueToken(continueToken)
          await this.setState({
            token: continueToken,
          })
        } catch {
          document.location.href = '/'
        }
      }
      await this.loadCards()
    }
  }

  handleInputChange(event: { target: { name: any; value: any } }) {
    const newState = { [event.target.name]: event.target.value } as Pick<
      State,
      keyof State
    >
    this.setState(newState)
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { stripe, elements } = this.props
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
  }

  loadCards = async () => {
    try {
      const res = await StripeService.getPaymentMethods(this.state.token)
      const cards = res.data.payment_methods
      await this.setState({
        isLoading: false,
        cards,
        miitel_id: res.data.miitel_id,
        official_account_name: res.data.official_account_name,
      })
    } catch {
      document.location.href = '/'
    }
  }

  detachPaymentMethod = async (paymentMethodId) => {
    await StripeService.detachPaymentMethods(this.state.token, paymentMethodId)
    await this.setState({
      isLoading: true,
    })
    await this.loadCards()
  }

  setMainPaymentMethod = async (paymentMethodId) => {
    await StripeService.setMainPaymentMethod(this.state.token, paymentMethodId)
    await this.setState({
      isLoading: true,
    })
    await this.loadCards()
  }

  render() {
    if (this.state.isLoading) {
      const loader = (
        <div style={{ margin: 80 + 'px' }} key="loader">
          <Loader active inline="centered" />
        </div>
      )
      return loader
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h1>{i18n.t('card_list.title')}</h1>
            <h2>
              {i18n.t('card_list.company_prefix')}
              {this.state.official_account_name}
            </h2>
            <h2>
              {i18n.t('card_list.tenant_prefix')}
              {this.state.miitel_id}
            </h2>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{i18n.t('card_list.no')}</Table.HeaderCell>
                  <Table.HeaderCell>
                    {i18n.t('card_list.brand')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {i18n.t('card_list.exp_month')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {i18n.t('card_list.exp_year')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {i18n.t('card_list.digits')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {i18n.t('card_list.main')}
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.cards.length > 0 ? (
                  this.state.cards.map((l, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{i + 1}</Table.Cell>
                      <Table.Cell>{l.brand}</Table.Cell>
                      <Table.Cell>{l.exp_month}</Table.Cell>
                      <Table.Cell>{l.exp_year}</Table.Cell>
                      <Table.Cell>**** **** **** {l.last4}</Table.Cell>
                      <Table.Cell>
                        <Popup
                          content={
                            l.main
                              ? i18n.t('card_list.to_use_as_main')
                              : i18n.t('card_list.not_to_use')
                          }
                          trigger={
                            <Icon
                              disabled={!l.main}
                              name="check circle"
                              color="green"
                            />
                          }
                          inverted
                          position="top center"
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Menu size="small" compact>
                          <Dropdown text="Edit" item simple>
                            <Dropdown.Menu direction="left" open={false}>
                              <Dropdown.Item
                                className="set-main"
                                icon="edit"
                                text="Set Main"
                                disabled={l.main}
                                onClick={() => this.setMainPaymentMethod(l.id)}
                              />
                              <Dropdown.Item
                                className="delete-card"
                                icon="trash"
                                text="delete"
                                disabled={l.main}
                                onClick={() => this.detachPaymentMethod(l.id)}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Menu>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={7} textAlign={'center'}>
                      {i18n.t('card_list.no_data')}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan="7">
                    <div style={{ float: 'right' }}>
                      {/* <Button
                        className="invoice-setting"
                        secondary
                        as={Link}
                        to={'/invoice-settings'}
                      >
                        <Icon name="credit card" />
                        {i18n.t('card_list.update_invoice_settings')}
                      </Button> */}
                      <Button
                        className="add-card"
                        secondary
                        as={Link}
                        to={'/cardsetup'}
                      >
                        <Icon name="credit card" />
                        {i18n.t('card_list.add_card')}
                      </Button>
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default CardList
