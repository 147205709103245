import { loadStripe } from '@stripe/stripe-js'

const env = process.env.REACT_APP_BUILD_ENV
let prefix = 'REACT_APP_DEVELOPMENT_'
console.log(env)
switch (env) {
  case 'p03-ogn101':
    prefix = 'REACT_APP_P01_OGN101_'
    break
  case 'production':
    prefix = 'REACT_APP_PRODUCTION_'
    break
  case 'staging':
    prefix = 'REACT_APP_STAGING_'
    break
}
console.log(prefix)

export const ConsoleAPIBaseURL = process.env[
  prefix + 'CONSOLE_BASE_API_URL'
] as string
export const ConsoleAPIKey = process.env[prefix + 'CONSOLE_API_KEY'] as string
export const StripePublicKey = process.env[
  prefix + 'STRIPE_PUBLIC_KEY'
] as string
export const stripePromise = loadStripe(StripePublicKey)
