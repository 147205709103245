import React from 'react'
import { Message } from 'semantic-ui-react'

class Error404 extends React.Component {
    render() {
        return (
            <div>
                <Message
                    color='yellow'
                    style={{ "marginTop": "40px", "marginLeft": "20px", "marginRight": "20px", "textAlign": "center" }}
                    size='large'
                >
                    404 Error
                </Message>
            </div>
        )
    }
}
export default Error404
