import axios from 'axios'
import { CardElement, CardNumberElement } from '@stripe/react-stripe-js'
import ConsoleAPIClient from 'service/utils/ConsoleAPIClient'
import { ConsoleAPIBaseURL } from 'config/BaseConfig'

export default class StripeService {
  static baseURL = ConsoleAPIBaseURL
  static headers = {
    Authorization: 'JWT ' + localStorage.getItem('access_token'),
    'Content-type': 'application/json',
  }

  static async getSetupIntent(token: string) {
    const data = {
      token: token,
    }
    return await ConsoleAPIClient.postWithoutAuth(
      '/api/v1/card/create-setup-intent',
      data,
    )
  }

  static async confirmCardSetup(stripe, elements, token) {
    const cardNumberElement = elements.getElement(CardNumberElement)
    const clientSecret = await this.getSetupIntent(token)
    const result = await stripe.confirmCardSetup(
      clientSecret.data.client_secret,
      {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: '',
          },
        },
      },
    )
    if (result.error) {
      console.log('[error]', result.error)
    } else {
      return result
    }
  }

  static async setMainPaymentMethod(token: string, paymentMethodId: string) {
    const data = {
      token: token,
      payment_method_id: paymentMethodId,
    }
    return await ConsoleAPIClient.postWithoutAuth(
      '/api/v1/card/set-main-payment-method',
      data,
    )
  }

  static async getPaymentMethods(token: string) {
    return await ConsoleAPIClient.getWithoutAuth(
      '/api/v1/card/list?token=' + token,
    )
  }

  static async detachPaymentMethods(token: string, paymentMethodId: string) {
    const data = {
      token: token,
      payment_method_id: paymentMethodId,
    }
    return await ConsoleAPIClient.postWithoutAuth('/api/v1/card/detach', data)
  }

  static async createPaymentMethod(stripe, elements) {
    const cardElement = elements.getElement(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      console.log('[error]', error)
    } else {
      console.log('[PaymentMethod]', paymentMethod)
      return paymentMethod
    }
  }

  static async createSubscription(paymentMethodId, priceId) {
    try {
      const res = await axios.post(
        this.baseURL + 'account/api/v1/create-subscription/',
        {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        },
        { headers: this.headers },
      )
      return res
    } catch (e) {
      document.location.href = '/'
      throw e
    }
  }

  static async checkHasSubscription() {
    return await ConsoleAPIClient.getWithAuth(
      '/account/api/v1/check-has-subscription/',
    )
  }
}
