import i18n from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import miitel_logo from '../images/miitel-logo-white.svg'

interface HeaderProps {}

interface HeaderState {
  isValidated: boolean
}

class Header extends React.Component<HeaderProps, HeaderState> {
  componentDidMount() {
    document.title = i18n.t('top.title')
  }

  render() {
    return (
      <Menu
        inverted
        fixed="top"
        style={{ background: '#1F323F', userSelect: 'none' }}
      >
        <Menu.Item
          style={{
            paddingTop: '12px',
            paddingBottom: '6px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Link to="/">
            <img
              src={miitel_logo}
              alt={'miitel'}
              height="28px"
              style={{ padding: '2px' }}
            />
          </Link>
        </Menu.Item>
      </Menu>
    )
  }
}

export default Header
