import i18n from 'i18next'
import React from 'react'
import { Grid, Header } from 'semantic-ui-react'

class LP extends React.Component {
  render() {
    return (
      <Grid>
        <Grid.Column textAlign="center">
          <Header as="h1">{i18n.t('top.title')}</Header>
          <div>
            {i18n.t('top.detail')}
            <br />
            {i18n.t('top.plz_access_via_issued_url')}
          </div>
        </Grid.Column>
      </Grid>
    )
  }
}

export default LP
