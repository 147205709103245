import i18n from 'i18next'
import React from 'react'
import { Button, Card, Form, Grid, Input } from 'semantic-ui-react'
import TokenAuthService from 'service/auth/TokenAuthService'
import Toast from '../common/Toast'

interface Props {}

interface State {
  isLoading: boolean
  token: string
  email: string
}

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: true,
      token: '',
      email: '',
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentDidMount() {
    const params = new URLSearchParams(document.location.search)
    await this.setState({
      token: params.get('token') || '',
    })
    if (this.state.token === '') {
      document.location.href = '/'
    }
  }

  handleInputChange(event: { target: { name: any; value: any } }) {
    const newState = { [event.target.name]: event.target.value } as Pick<
      State,
      keyof State
    >
    this.setState(newState)
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await TokenAuthService.LoginAndGetContinueToken(
        this.state.token,
        this.state.email,
      )
      await sessionStorage.setItem('continue_token', res.data.continue_token)
      document.location.href = '/cards'
    } catch {
      Toast.error(i18n.t('login.error'))
    }
  }

  render() {
    return (
      <Grid centered>
        <Grid.Row columns={2} centered>
          <Grid.Column>
            <Card style={{ width: '100%', maxWidth: '800px', padding: '10px' }}>
              <Card.Content>
                <Card.Header>{i18n.t('login.title')}</Card.Header>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>{i18n.t('login.text')}</label>
                      <Input
                        className="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        name="email"
                        type="email"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal" style={{ float: 'right' }}>
                    <Button
                      className="regist-card"
                      secondary
                      onClick={this.handleSubmit}
                    >
                      {i18n.t('common.login')}
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default Login
