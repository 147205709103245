import { Elements } from '@stripe/react-stripe-js'
import CardList from 'components/CardList'
import CardSetup from 'components/CardSetup'
import Error404 from 'components/Error404'
import Header from 'components/Header'
import InvoiceSettingsFrom from 'components/InvoiceSettings'
import Login from 'components/Login'
import LP from 'components/LP'
import { stripePromise } from 'config/BaseConfig'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Container } from 'semantic-ui-react'

function App() {
  return (
    <div
      id="main_content"
      style={{ top: '50px', position: 'absolute', width: '100%' }}
    >
      <BrowserRouter basename="/">
        <Elements stripe={stripePromise}>
          <Header />
          <Container style={{ paddingTop: '20px' }}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/cardsetup" component={CardSetup} />
              <Route path="/cards" component={CardList} />
              <Route path="/invoice-settings" component={InvoiceSettingsFrom} />
              <Route path="/error" component={Error404} />
              <Route path="*" component={LP} />
            </Switch>
          </Container>
        </Elements>
      </BrowserRouter>
    </div>
  )
}

export default App
