import ConsoleAPIClient from 'service/utils/ConsoleAPIClient'
import { ConsoleAPIBaseURL } from 'config/BaseConfig'

export default class TokenAuthService {
  static baseURL = ConsoleAPIBaseURL
  static headers = {
    Authorization: 'JWT ' + localStorage.getItem('access_token'),
    'Content-type': 'application/json',
  }

  static async LoginAndGetContinueToken(token: string, email: string) {
    const data = {
      email: email,
      token: token,
    }
    return await ConsoleAPIClient.postWithoutAuth(
      '/api/v1/customer/check-address',
      data,
    )
  }

  static async CheckContinueToken(continue_token: string) {
    const data = {
      continue_token: continue_token,
    }
    return await ConsoleAPIClient.postWithoutAuth(
      '/api/v1/customer/check-login',
      data,
    )
  }
}
