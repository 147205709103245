import i18n from "i18next";
import { initReactI18next } from "react-i18next";
var ja = require('./lang/ja.json');
var en = require('./lang/en.json');

const language = (window.navigator.languages && window.navigator.languages[0]) ||
            window.navigator.language ||
            window.navigator.userLanguage ||
            window.navigator.browserLanguage;

let lng = "en"
if(language==="ja"){
  lng = language
}

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en,ja
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng,
    keySeparator: ".",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;